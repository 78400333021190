<template>
  <div class="card">
    <div class="card-body">
      <validation-observer ref="observer_branch" v-slot="{ invalid }">
        <form @submit.prevent="doAction">
          <div class="row" v-if="!updateBranch">
            <div class="col-12 pb-0">
              <h6>
                <v-icon class="pb-1">mdi-numeric-1-box-outline</v-icon>
                Tipo de sucursal
              </h6>
            </div>
            <div class="col-md-4">
              <validation-provider
                v-slot="{ errors }"
                name="type_branch"
                rules="required"
              >
                <v-autocomplete
                  auto-select-first
                  :items="filter_types_branch"
                  v-model="modelForm.branchOfficeTypeId"
                  :error-messages="errors"
                  :no-data-text="$t('label.no_results')"
                  :label="$t('label.branch_type')"
                >
                </v-autocomplete>
              </validation-provider>
            </div>

            <div class="col-md-8">
              <validation-provider
                v-slot="{ errors }"
                name="supplier"
                :rules="view_form ? 'required' : ''"
              >
                <v-autocomplete
                  item-value="idSupplier"
                  item-text="name"
                  autocomplete
                  required
                  :disabled="!view_form"
                  :items="listsupplier"
                  data-vv-name="select"
                  clearable
                  clear-icon="feather icon-x-circle"
                  return-object
                  v-model="supplierInfo"
                  :error-messages="errors"
                  :no-data-text="$t('label.no_results')"
                  label="Proveedor"
                >
                </v-autocomplete>
              </validation-provider>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h6>
                <v-icon class="pb-1">
                  {{
                    updateBranch
                      ? "mdi-numeric-1-box-outline"
                      : "mdi-numeric-2-box-outline"
                  }}
                </v-icon>
                {{ $t("branch_office.information_branch") }}
              </h6>
            </div>
            <div class="col-md-12">
              <validation-provider
                v-slot="{ errors }"
                name="name"
                :rules="'required|max:255' + $UtilFront.RegExSpace()"
              >
                <v-text-field
                  v-model.trim="modelForm.name"
                  maxlength="255"
                  :disabled="view_form"
                  :counter="255"
                  oninput="this.value = this.value.toUpperCase()"
                  :error-messages="errors"
                  :label="$t('label.name')"
                  required
                  autocomplete="off"
                ></v-text-field>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <validation-provider
                v-slot="{ errors }"
                name="clues"
                :rules="'required|max:50' + $UtilFront.RegExNoSpace()"
              >
                <v-text-field
                  v-model.trim="modelForm.branchOfficeKey"
                  maxlength="50"
                  oninput="this.value = this.value.toUpperCase()"
                  :counter="50"
                  :error-messages="errors"
                  :label="$t('label.clues*')"
                  required
                  :disabled="view_form"
                  autocomplete="off"
                ></v-text-field>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <validation-provider
                v-slot="{ errors }"
                name="warehouse_ID"
                :rules="'required|max:50' + $UtilFront.RegExNoSpace()"
              >
                <v-text-field
                  v-model.trim="modelForm.warehouseId"
                  maxlength="50"
                  :counter="50"
                  :error-messages="errors"
                  oninput="this.value = this.value.toUpperCase()"
                  :label="$t('label.warehouse_ID')"
                  required
                  :disabled="view_form"
                  autocomplete="off"
                ></v-text-field>
              </validation-provider>
            </div>
            <div v-if="updateBranch" class="col-md-4">
              <validation-provider
                v-slot="{ errors }"
                name="type_branch"
                rules="required"
              >
                <v-autocomplete
                  auto-select-first
                  :items="filter_types_branch"
                  v-model="modelForm.branchOfficeTypeId"
                  :error-messages="errors"
                  :no-data-text="$t('label.no_results')"
                  :label="$t('label.branch_type')"
                >
                </v-autocomplete>
              </validation-provider>
            </div>
            <div class="col-md-4">
              <validation-provider
                v-slot="{ errors }"
                name="tag"
                rules="required"
              >
                <v-autocomplete
                  auto-select-first
                  :items="tags"
                  attach
                  v-model="modelForm.tagId"
                  :disabled="view_form"
                  :error-messages="errors"
                  :no-data-text="$t('label.no_results')"
                  :label="$t('label.tag')"
                >
                </v-autocomplete>
              </validation-provider>
            </div>
            <div v-bind:class="updateBranch ? 'col-md-4' : 'col-md-8'">
              <validation-provider
                v-slot="{ errors }"
                name="jurisdiction"
                rules="required"
              >
                <v-autocomplete
                  auto-select-first
                  :items="jurisdictions"
                  attach
                  :disabled="view_form"
                  v-model="modelForm.jurisdictionId"
                  :error-messages="errors"
                  :no-data-text="$t('label.no_results')"
                  :label="$t('label.jurisdiction')"
                >
                </v-autocomplete>
              </validation-provider>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h6>
                <v-icon class="pb-1">
                  {{
                    updateBranch
                      ? "mdi-numeric-2-box-outline"
                      : "mdi-numeric-3-box-outline"
                  }}
                </v-icon>
                {{ $t("branch_office.branch_address") }}
              </h6>
            </div>
            <div class="col-md-8">
              <validation-provider
                v-slot="{ errors }"
                name="street"
                rules="required|max:100"
              >
                <v-text-field
                  v-model.trim="modelForm.street"
                  maxlength="100"
                  :counter="100"
                  :disabled="view_form"
                  :error-messages="errors"
                  :label="$t('label.street')"
                  required
                  oninput="this.value = this.value.toUpperCase()"
                  autocomplete="off"
                ></v-text-field>
              </validation-provider>
            </div>
            <div class="col-md-2">
              <validation-provider
                v-slot="{ errors }"
                name="outside_number"
                rules="required|numeric|max:10"
              >
                <v-text-field
                  v-model.trim="modelForm.outdoorNumber"
                  maxlength="10"
                  :counter="10"
                  :disabled="view_form"
                  :error-messages="errors"
                  :label="$t('label.outside_number')"
                  required
                  autocomplete="off"
                ></v-text-field>
              </validation-provider>
            </div>
            <div class="col-md-2">
              <validation-provider
                v-slot="{ errors }"
                name="inside_number"
                rules="required|numeric|max:10"
              >
                <v-text-field
                  v-model.trim="modelForm.interiorNumber"
                  maxlength="10"
                  :counter="10"
                  :disabled="view_form"
                  :error-messages="errors"
                  :label="$t('label.inside_number')"
                  required
                  autocomplete="off"
                ></v-text-field>
              </validation-provider>
            </div>
            <div class="col-md-8">
              <validation-provider
                v-slot="{ errors }"
                name="colonia"
                rules="required|max:100"
              >
                <v-text-field
                  v-model.trim="modelForm.suburb"
                  maxlength="100"
                  :disabled="view_form"
                  :counter="100"
                  :error-messages="errors"
                  oninput="this.value = this.value.toUpperCase()"
                  :label="$t('label.colonia')"
                  required
                  autocomplete="off"
                ></v-text-field>
              </validation-provider>
            </div>
            <div class="col-md-4">
              <validation-provider
                v-slot="{ errors }"
                name="zip_code"
                rules="required|numeric|max:10"
              >
                <v-text-field
                  v-model.trim="modelForm.postalCode"
                  maxlength="10"
                  :counter="10"
                  :disabled="view_form"
                  :error-messages="errors"
                  :label="$t('label.zip_code')"
                  required
                  autocomplete="off"
                ></v-text-field>
              </validation-provider>
            </div>
            <div class="col-md-4">
              <validation-provider
                v-slot="{ errors }"
                name="city"
                rules="required"
              >
                <v-autocomplete
                  required
                  :error-messages="errors"
                  :disabled="view_form"
                  v-model="modelForm.provinceId"
                  :items="provinceList"
                  :label="$t('label.city')"
                />
              </validation-provider>
            </div>

            <div class="col-md-4">
              <validation-provider
                v-slot="{ errors }"
                name="road"
                rules="required|max:100"
              >
                <v-text-field
                  oninput="this.value = this.value.toUpperCase()"
                  v-model.trim="modelForm.road"
                  maxlength="100"
                  :counter="100"
                  :disabled="view_form"
                  :error-messages="errors"
                  :label="$t('label.road')"
                  required
                  autocomplete="off"
                ></v-text-field>
              </validation-provider>
            </div>
            <div class="col-md-4">
              <validation-provider
                v-slot="{ errors }"
                name="location"
                rules="required|max:100"
              >
                <v-text-field
                  v-model.trim="modelForm.location"
                  maxlength="100"
                  :counter="100"
                  :disabled="view_form"
                  oninput="this.value = this.value.toUpperCase()"
                  :error-messages="errors"
                  :label="$t('label.location')"
                  required
                  autocomplete="off"
                ></v-text-field>
              </validation-provider>
            </div>
          </div>
          <div v-if="modelForm.branchOfficeTypeId == 5" class="row">
            <div class="col-md-12 text-right">
              <button
                class="btn btn-outline-success"
                :disabled="validationSupplier"
              >
                <i class="feather icon-save mr-1"></i>
                {{ $t("global.save_branch") }}
              </button>
            </div>
          </div>
          <div v-if="modelForm.branchOfficeTypeId != 5" class="row">
            <div class="col-md-12 text-right">
              <button class="btn btn-outline-success" :disabled="invalid">
                <i class="feather icon-save mr-1"></i>
                {{ $t("global.save_branch") }}
              </button>
            </div>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import tags from "@/assets/json/tags.json";
export default {
  props: {
    modelForm: Object,
    jurisdictions: Array,
    provinceList: Array,
    clearModel: Function,
    doAction: Function,
    branchType: Array,
    supplier: Array,
    updateBranch: {
      required: true,
    },
  },
  data() {
    return {
      typesBranchOffice: this.branchType,
      tags: tags,
      supplierInfo: "",
      listsupplier: this.supplier,
      typeBefore: null,
      validationSupplier: true,
    };
  },
  computed: {
    view_form() {
      return this.modelForm.branchOfficeTypeId == 5;
    },
    filter_types_branch() {
      if (this.updateBranch)
        return this.typesBranchOffice.filter((type) => type.value != 5);
      else return this.typesBranchOffice;
    },
  },
  methods: {},
  watch: {
    "modelForm.branchOfficeTypeId": async function() {
      if (!this.updateBranch) {
        this.supplierInfo = "";
        if (this.modelForm.branchOfficeTypeId == 5) {
          const { isConfirmed } = await this.$SwalConfirm.fire({
            text:
              "Cambiar el tipo de esta sucursal a proveedor reemplazará cualquier información previamente ingresada.",
            icon: "warning",
          });
          if (!isConfirmed) {
            this.modelForm.branchOfficeTypeId = this.typeBefore;
            return;
          }
          this.clearModel();
          this.$refs.observer_branch.reset();
        }
        if (this.typeBefore == 5 || this.modelForm.branchOfficeTypeId == 5) {
          this.clearModel();
          this.$refs.observer_branch.reset();
        }
        this.typeBefore = this.modelForm.branchOfficeTypeId;
      }
    },
    supplierInfo() {
      this.validationSupplier = true;
      if (this.supplierInfo) {
        this.modelForm.name = this.supplierInfo.name;
        this.modelForm.branchOfficeKey = this.supplierInfo.supplierKey;
        this.modelForm.warehouseId = this.supplierInfo.supplierKey;
        this.modelForm.supplierId = this.supplierInfo.idSupplier;
        this.modelForm.branchOfficeTypeId = this.modelForm.branchOfficeTypeId
          ? this.modelForm.branchOfficeTypeId
          : "";
        this.modelForm.tagId =  3;
        this.modelForm.jurisdictionId = 1;
        this.modelForm.street = "-";
        this.modelForm.outdoorNumber = 0;
        this.modelForm.interiorNumber = 0;
        this.modelForm.suburb = "-";
        this.modelForm.postalCode = 0;
        this.modelForm.provinceId = "1";
        this.modelForm.road = "-";
        this.modelForm.location = "-";
        this.validationSupplier = false;
      }
    },
  },
};
</script>
