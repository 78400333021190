var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataList,"search":_vm.search,"fixed-header":"","height":"auto","footer-props":{
      'items-per-page-text': _vm.$t('table.item_table'),
      'items-per-page-all-text': _vm.$t('table.all'),
      'items-per-page-options': [15, 30, 50, 100],
    },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search'),"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row justify-end"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 pr-6 text-right"},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('table.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.statusForReport",fn:function(ref){
    var item = ref.item;
return [(item.statusForReport)?_c('div',[_c('i',{staticClass:"mdi mdi-check-circle success-color"})]):_c('div',[_c('i',{staticClass:"mdi mdi-close-circle error-color"})])]}},{key:"item.statusForReception",fn:function(ref){
    var item = ref.item;
return [(item.statusForReception)?_c('div',[_c('i',{staticClass:"mdi mdi-check-circle success-color"})]):_c('div',[_c('i',{staticClass:"mdi mdi-close-circle error-color"})])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(_vm.isUpdate)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"disabled":item.branchOfficeTypeId == 5,"color":item.branchOfficeTypeId == 5 ? '#fff' : '',"small":""},on:{"click":function($event){return _vm.updateBranchOffice(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi mdi-square-edit-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.edit")))])]):_vm._e(),(_vm.isOptions)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.showOptions(item)}}},'v-icon',attrs,false),on),[_vm._v(" feather icon-settings ")])]}}],null,true)},[_c('span',[_vm._v("Opciones avanzadas")])]):_vm._e()]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('div',[(_vm.isUpdateStatus)?_c('v-switch',{staticClass:"hand",attrs:{"color":"success","hide-details":"","dense":"","inset":""},on:{"change":function($event){return _vm.confirmchangeStatus(item)}},model:{value:(item.available),callback:function ($$v) {_vm.$set(item, "available", $$v)},expression:"item.available"}}):_c('div',[_vm._v(" "+_vm._s(item.available ? "Activa" : "Inactiva")+" ")])],1)]}}],null,true)}),(_vm.isButton)?_c('ButtonTableComponent',{on:{"excelTable":_vm.excelTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }