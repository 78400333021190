var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('validation-observer',{ref:"observer_branch",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.doAction.apply(null, arguments)}}},[(!_vm.updateBranch)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pb-0"},[_c('h6',[_c('v-icon',{staticClass:"pb-1"},[_vm._v("mdi-numeric-1-box-outline")]),_vm._v(" Tipo de sucursal ")],1)]),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"type_branch","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"auto-select-first":"","items":_vm.filter_types_branch,"error-messages":errors,"no-data-text":_vm.$t('label.no_results'),"label":_vm.$t('label.branch_type')},model:{value:(_vm.modelForm.branchOfficeTypeId),callback:function ($$v) {_vm.$set(_vm.modelForm, "branchOfficeTypeId", $$v)},expression:"modelForm.branchOfficeTypeId"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-8"},[_c('validation-provider',{attrs:{"name":"supplier","rules":_vm.view_form ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"item-value":"idSupplier","item-text":"name","autocomplete":"","required":"","disabled":!_vm.view_form,"items":_vm.listsupplier,"data-vv-name":"select","clearable":"","clear-icon":"feather icon-x-circle","return-object":"","error-messages":errors,"no-data-text":_vm.$t('label.no_results'),"label":"Proveedor"},model:{value:(_vm.supplierInfo),callback:function ($$v) {_vm.supplierInfo=$$v},expression:"supplierInfo"}})]}}],null,true)})],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h6',[_c('v-icon',{staticClass:"pb-1"},[_vm._v(" "+_vm._s(_vm.updateBranch ? "mdi-numeric-1-box-outline" : "mdi-numeric-2-box-outline")+" ")]),_vm._v(" "+_vm._s(_vm.$t("branch_office.information_branch"))+" ")],1)]),_c('div',{staticClass:"col-md-12"},[_c('validation-provider',{attrs:{"name":"name","rules":'required|max:255' + _vm.$UtilFront.RegExSpace()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"maxlength":"255","disabled":_vm.view_form,"counter":255,"oninput":"this.value = this.value.toUpperCase()","error-messages":errors,"label":_vm.$t('label.name'),"required":"","autocomplete":"off"},model:{value:(_vm.modelForm.name),callback:function ($$v) {_vm.$set(_vm.modelForm, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"modelForm.name"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"clues","rules":'required|max:50' + _vm.$UtilFront.RegExNoSpace()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"maxlength":"50","oninput":"this.value = this.value.toUpperCase()","counter":50,"error-messages":errors,"label":_vm.$t('label.clues*'),"required":"","disabled":_vm.view_form,"autocomplete":"off"},model:{value:(_vm.modelForm.branchOfficeKey),callback:function ($$v) {_vm.$set(_vm.modelForm, "branchOfficeKey", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"modelForm.branchOfficeKey"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"warehouse_ID","rules":'required|max:50' + _vm.$UtilFront.RegExNoSpace()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"maxlength":"50","counter":50,"error-messages":errors,"oninput":"this.value = this.value.toUpperCase()","label":_vm.$t('label.warehouse_ID'),"required":"","disabled":_vm.view_form,"autocomplete":"off"},model:{value:(_vm.modelForm.warehouseId),callback:function ($$v) {_vm.$set(_vm.modelForm, "warehouseId", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"modelForm.warehouseId"}})]}}],null,true)})],1),(_vm.updateBranch)?_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"type_branch","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"auto-select-first":"","items":_vm.filter_types_branch,"error-messages":errors,"no-data-text":_vm.$t('label.no_results'),"label":_vm.$t('label.branch_type')},model:{value:(_vm.modelForm.branchOfficeTypeId),callback:function ($$v) {_vm.$set(_vm.modelForm, "branchOfficeTypeId", $$v)},expression:"modelForm.branchOfficeTypeId"}})]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"tag","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"auto-select-first":"","items":_vm.tags,"attach":"","disabled":_vm.view_form,"error-messages":errors,"no-data-text":_vm.$t('label.no_results'),"label":_vm.$t('label.tag')},model:{value:(_vm.modelForm.tagId),callback:function ($$v) {_vm.$set(_vm.modelForm, "tagId", $$v)},expression:"modelForm.tagId"}})]}}],null,true)})],1),_c('div',{class:_vm.updateBranch ? 'col-md-4' : 'col-md-8'},[_c('validation-provider',{attrs:{"name":"jurisdiction","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"auto-select-first":"","items":_vm.jurisdictions,"attach":"","disabled":_vm.view_form,"error-messages":errors,"no-data-text":_vm.$t('label.no_results'),"label":_vm.$t('label.jurisdiction')},model:{value:(_vm.modelForm.jurisdictionId),callback:function ($$v) {_vm.$set(_vm.modelForm, "jurisdictionId", $$v)},expression:"modelForm.jurisdictionId"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h6',[_c('v-icon',{staticClass:"pb-1"},[_vm._v(" "+_vm._s(_vm.updateBranch ? "mdi-numeric-2-box-outline" : "mdi-numeric-3-box-outline")+" ")]),_vm._v(" "+_vm._s(_vm.$t("branch_office.branch_address"))+" ")],1)]),_c('div',{staticClass:"col-md-8"},[_c('validation-provider',{attrs:{"name":"street","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"maxlength":"100","counter":100,"disabled":_vm.view_form,"error-messages":errors,"label":_vm.$t('label.street'),"required":"","oninput":"this.value = this.value.toUpperCase()","autocomplete":"off"},model:{value:(_vm.modelForm.street),callback:function ($$v) {_vm.$set(_vm.modelForm, "street", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"modelForm.street"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-2"},[_c('validation-provider',{attrs:{"name":"outside_number","rules":"required|numeric|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"maxlength":"10","counter":10,"disabled":_vm.view_form,"error-messages":errors,"label":_vm.$t('label.outside_number'),"required":"","autocomplete":"off"},model:{value:(_vm.modelForm.outdoorNumber),callback:function ($$v) {_vm.$set(_vm.modelForm, "outdoorNumber", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"modelForm.outdoorNumber"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-2"},[_c('validation-provider',{attrs:{"name":"inside_number","rules":"required|numeric|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"maxlength":"10","counter":10,"disabled":_vm.view_form,"error-messages":errors,"label":_vm.$t('label.inside_number'),"required":"","autocomplete":"off"},model:{value:(_vm.modelForm.interiorNumber),callback:function ($$v) {_vm.$set(_vm.modelForm, "interiorNumber", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"modelForm.interiorNumber"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-8"},[_c('validation-provider',{attrs:{"name":"colonia","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"maxlength":"100","disabled":_vm.view_form,"counter":100,"error-messages":errors,"oninput":"this.value = this.value.toUpperCase()","label":_vm.$t('label.colonia'),"required":"","autocomplete":"off"},model:{value:(_vm.modelForm.suburb),callback:function ($$v) {_vm.$set(_vm.modelForm, "suburb", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"modelForm.suburb"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"zip_code","rules":"required|numeric|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"maxlength":"10","counter":10,"disabled":_vm.view_form,"error-messages":errors,"label":_vm.$t('label.zip_code'),"required":"","autocomplete":"off"},model:{value:(_vm.modelForm.postalCode),callback:function ($$v) {_vm.$set(_vm.modelForm, "postalCode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"modelForm.postalCode"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"required":"","error-messages":errors,"disabled":_vm.view_form,"items":_vm.provinceList,"label":_vm.$t('label.city')},model:{value:(_vm.modelForm.provinceId),callback:function ($$v) {_vm.$set(_vm.modelForm, "provinceId", $$v)},expression:"modelForm.provinceId"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"road","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"oninput":"this.value = this.value.toUpperCase()","maxlength":"100","counter":100,"disabled":_vm.view_form,"error-messages":errors,"label":_vm.$t('label.road'),"required":"","autocomplete":"off"},model:{value:(_vm.modelForm.road),callback:function ($$v) {_vm.$set(_vm.modelForm, "road", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"modelForm.road"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"location","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"maxlength":"100","counter":100,"disabled":_vm.view_form,"oninput":"this.value = this.value.toUpperCase()","error-messages":errors,"label":_vm.$t('label.location'),"required":"","autocomplete":"off"},model:{value:(_vm.modelForm.location),callback:function ($$v) {_vm.$set(_vm.modelForm, "location", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"modelForm.location"}})]}}],null,true)})],1)]),(_vm.modelForm.branchOfficeTypeId == 5)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-right"},[_c('button',{staticClass:"btn btn-outline-success",attrs:{"disabled":_vm.validationSupplier}},[_c('i',{staticClass:"feather icon-save mr-1"}),_vm._v(" "+_vm._s(_vm.$t("global.save_branch"))+" ")])])]):_vm._e(),(_vm.modelForm.branchOfficeTypeId != 5)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-right"},[_c('button',{staticClass:"btn btn-outline-success",attrs:{"disabled":invalid}},[_c('i',{staticClass:"feather icon-save mr-1"}),_vm._v(" "+_vm._s(_vm.$t("global.save_branch"))+" ")])])]):_vm._e()])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }