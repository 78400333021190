<template>
  <!-- Start Containerbar -->
  <div id="containerbar">
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
            <h4 class="page-title">{{ $t("branch_office.supplies") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("branch_office.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  {{ $t("branch_office.administration") }}
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
              </ol>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button">
            <button
              class="btn btn-outline-success"
              v-if="
                showAction(1) && withPermissionName(permissions.CAT_BRAN_ADD_01)
              "
              @click="changeAction(2)"
            >
              <i class="feather icon-plus mr-1"></i>
              {{ $t("global.create_branch") }}
            </button>
            <button
              class="btn btn-outline-success"
              v-if="showAction(2) || showAction(3) || showAction(4)"
              @click="changeAction(1)"
            >
              <i class="feather icon-arrow-left mr-1"></i>
              {{ $t("global.return_to") }}
            </button>
          </div>
        </div>
      </div>
      <div class="contentbar">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <AlertMessageComponent ref="alertMessageComponent" />
              <div class="card-header">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title">
                      <i class="feather icon-truck mr-2"></i>
                      {{ $t(titleAction) }}
                    </h5>
                  </div>
                  <div class="col-md-3">
                    <v-autocomplete
                      v-show="showAction(1)"
                      v-model="filterType"
                      :items="branchType"
                      dense
                      outlined
                      class="br"
                      clearable
                      label="Tipo de sucursal"
                      data-vv-name="select"
                    ></v-autocomplete>
                  </div>
                </div>
              </div>
              <div v-show="showAction(1)">
                <BranchOfficeTableComponent
                  ref="tableBranch"
                  :headers="headers"
                  :isButton="true"
                  :dataList="filterTable"
                  @excelTable="excelTable"
                  @updateBranchOffice="getBranchUpdate"
                  @showOptions="showOptions"
                  @confirmchangeStatus="confirmchangeStatus"
                  :isUpdate="withPermissionName(permissions.CAT_BRAN_EDIT_02)"
                  :isOptions="
                    withPermissionName(permissions.CAT_BRAN_STA_ADV_05)
                  "
                  :isUpdateStatus="
                    withPermissionName(permissions.CAT_BRAN_STA_03)
                  "
                />
              </div>

              <div v-if="showAction(2) || showAction(3)">
                <FormBranchOfficeComponents
                  :modelForm="modelForm"
                  :jurisdictions="jurisdictions"
                  :provinceList="provinceList"
                  :clearModel="clearModel"
                  :doAction="doAction"
                  :branchType="branchType"
                  :supplier="supplier"
                  :updateBranch="updateBranch"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog" max-width="500">
      <div class="card">
        <div class="card-header pb-0 text-center text-uppercase">
          <h5>{{ model.name }} - {{ model.branchOfficeTypeName }}</h5>
        </div>
        <div class="card-body">
          <template>
            <div>
              <v-alert class="card card-excel pdf" text color="#023047"
                ><h6>
                  El estado actual de la sucursal puede indicar si está
                  habilitada o no para la recepcion de sus envios.
                </h6>
                <i class="feather icon-truck"></i>
                <v-list-item two-line>
                  <v-list-item-action>
                    <v-switch
                      color="#023047"
                      v-model="model.statusForReception"
                      @click="confirmchangeStatusAdvanced(true)"
                      inset
                    ></v-switch>
                  </v-list-item-action>
                  <v-list-item-content>
                    <h6 class="mb-0">
                      Estado actual de la sucursal para recepción de envíos.
                    </h6>
                  </v-list-item-content>
                </v-list-item></v-alert
              >
            </div>
          </template>
          <template>
            <div>
              <v-alert class="card card-excel excel" text color="success"
                ><h6>
                  El estado actual de la sucursal indica que puede o no estar
                  disponible para ser incluida en los reportes del sistema.
                </h6>
                <i class="feather icon-file-text"></i>
                <v-list-item two-line>
                  <v-list-item-action>
                    <v-switch
                      color="#4caf50"
                      v-model="model.statusForReport"
                      @click="confirmchangeStatusAdvanced(false)"
                      inset
                    ></v-switch>
                  </v-list-item-action>
                  <v-list-item-content>
                    <h6 class="mb-0">
                      Estado actual de la sucursal en los reportes del sistema.
                    </h6>
                  </v-list-item-content>
                </v-list-item></v-alert
              >
            </div>
          </template>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
/**
 * @module BranchOfficeView
 * @desc Catalogo Sucursales
 * @author Yair Alvarez
 */
import {
  BranchOfficeRequest,
  JurisdictionRequest,
  ExcelRequest,
  ProvinceRequest,
} from "@/core/request";

import FormBranchOfficeComponents from "@/components/branchoffice/FormBranchOfficeComponents.vue";
import AllProvincesComponent from "@/common/select/AllProvincesComponent.vue";
import BranchOfficeTableComponent from "@/components/branchoffice/BranchOfficeTableComponent.vue";
import { BRANCH_OFFICE } from "@/core/DataTableHeadersLocale";

export default {
  props: {
    /** @vprop {} .*/
  },
  data() {
    return {
      /** * @member {Array} title               - View title . / Titulo de la vista. */
      /** * @member {Object} headers            - Default table headers. / Cabezeras default de la tabla sucursales. */
      /** * @member {Boolean} action            - Default value for view action (branches list). / Valor default para la accion de vista (lista sucursales). */
      /** * @member {number} model              - Branch model. / Modelo de sucursal.*/
      /** * @member {Object} modelForm          - Address Branch model. / Modelo de direccion de la sucursal.  */
      /** * @member {Object} branchType      - All Branches Array. / Arreglo de todos los tipos de sucursales. */
      /** * @member {Object} typesBranchOffice  - Default headers for details table. / Cabezeras default para la tabla de detalle. */
      /** * @member {number} tags               - Tags Array. / Arreglo de etiquetas. */
      /** * @member {Object} jurisdictions      - All Jurisdictions Array. / Arreglo de todas las juridcciones. */
      /** * @member {Object} dialog             - Default value for dialog. / Valor default para dialog.  */
      /** * @member {Array} supplier       - Chage status Alert confirmation values. / Valores de la alerta de confirmacion de cambio de status. */
      /** * @member {Object} permissions        - Permissions constants. / Constantes de permisos. */
      /** * @member {number} idUser             - */
      /** * @member {Object} idProvince         - */

      title: "branch_office.branch",
      headers: BRANCH_OFFICE(),
      action: 1,
      dialog: false,
      model: {},
      modelForm: {
        name: "",
        branchOfficeKey: "",
        warehouseId: "",
        branchOfficeTypeId: "",
        tagId: "",
        jurisdictionId: "",
        street: "",
        outdoorNumber: "",
        interiorNumber: "",
        suburb: "",
        postalCode: "",
        parent: null,
        provinceId: 0,
        road: "",
        location: "",
        supplierId: "",
      },
      filterType: null,
      modelFormCopy: {},
      branchType: [],
      jurisdictions: [],
      tableData: [],
      supplier: [],
      updateBranch: false,
      permissions: this.$PermissionConstants,
      idProvince: 0,
      provinceList: [],
    };
  },
  computed: {
    /**
     * @computed {String} Sets and returns views titles. / Establece y retorna titulos de vistas.
     */
    titleAction() {
      const title = {
        1: "branch_office.branch",
        2: "branch_office.register_branch",
        3: "branch_office.edit_branch",
      };
      return title[this.action] || "Listado";
    },
    filterTable() {
      const filteredData = this.filterType
        ? this.tableData.filter(
            (table) => table.branchOfficeTypeId == this.filterType
          )
        : this.tableData;
      return filteredData;
    },
  },
  methods: {
    /**
     * Envia datos y muestra el componente de alerta. / Sends data and shows to alert component.
     * @method
     * @param {string} type - Alert type / Tipo de alerta.
     * @param {string} message - Alert message. / Mensaje de alerta.
     */
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    showAction(action) {
      return this.action == action;
    },
    clearModel() {
      this.modelForm = {
        name: "",
        branchOfficeKey: "",
        warehouseId: "",
        branchOfficeTypeId: this.modelForm.branchOfficeTypeId
          ? this.modelForm.branchOfficeTypeId
          : "",
        tagId: "",
        jurisdictionId: "",
        street: "",
        outdoorNumber: "",
        interiorNumber: "",
        suburb: "",
        postalCode: "",
        parent: null,
        provinceId: "",
        road: "",
        location: "",
        supplierId: "",
      };
    },
    /**
     * Resets variables in use and sets action view. // Reestablece variables en uso y establece la accion de vista.
     * @method
     * @param {number} action - View.
     * 1 = history view
     * 2 = create
     * 3 = edit
     */
    changeAction(action) {
      switch (action) {
        case 1:
        case 3:
          this.action = action;
          break;
        case 2:
          this.updateBranch = false;
          this.modelFormCopy = {};
          this.action = action;
          this.modelForm.branchOfficeTypeId = "";
          this.clearModel();
          break;
        default:
          break;
      }
    },
    /**
     * Calls action function. // Llama a la funcion de accion.
     * @method
     * @param {number} action - View.
     * 2 = create
     * 3 = edit
     */
    async doAction() {
      if (this.action == 2) {
        this.addBranchOffice();
      } else if (this.action == 3) {
        const payload = this.$UtilFront.getUpdateoObject(
          this.modelForm,
          this.modelFormCopy
        );
        if (payload) await this.updateBranchOffice(payload);
        else this.alert("warning", "Update_Error_0004");
      }
    },
    async listAllProvinces() {
      await ProvinceRequest.listAll().then((response) => {
        let { data } = response.data.data;
        this.provinceList = data;
      });
    },

    /**
     * Gets all branches. // Obtiene todas las sucursales.
     * @method
     */
    async listAll() {
      try {
        this.loading = this.$UtilFront.getLoadding();
        const res = await BranchOfficeRequest.listAll();
        const { objects } = res.data.data;
        const list = objects.map((obj) => ({
          ...obj,
          fullAddress: obj.fullAddress || "Sin dirección",
          available: obj.status == "Activo",
        }));
        this.tableData = list;
      } catch (error) {
        console.log(error);
        this.alert("error", error.message);
      } finally {
        this.loading.hide();
      }
    },
    /**
     * Adds new branch. // Agrega nueva sucursal.
     * Reloads allProvincesComponent. // Recarga allProvincesComponent
     * @method
     */
    async addBranchOffice() {
      const { isConfirmed } = await this.$SwalConfirm.fire({
        icon: "warning",
        html:
          "La sucursal <strong>" +
          this.modelForm.name +
          "</strong> será registrada.",
      });
      if (!isConfirmed) {
        return;
      }
      this.loading = this.$UtilFront.getLoadding();
      if (typeof this.modelForm.parent === "undefined") {
        this.modelForm.parent = 0;
      }
      this.modelForm.userBranchOfficeDTO = {
        idUser: this.$UtilFront.getDataUser().idUser,
        idBranchOffice: 0,
      };
      BranchOfficeRequest.addBranchOffice(this.modelForm)
        .then((res) => {
          let { data } = res.data;
          let { message, success } = data;
          if (success) {
            this.changeAction(1);
            this.alert("success", "Add_Success_0001");
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
          this.listAll();
        });
    },
    /**
     * Edits branch. // Edita una sucursal.
     * @method
     */
    async updateBranchOffice(payload) {
      this.loading = this.$UtilFront.getLoadding();
      payload.idBranchOffice = this.modelForm.idBranchOffice;
      payload.userBranchOfficeDTO = {
        idUser: this.$UtilFront.getDataUser().idUser,
      };
      BranchOfficeRequest.updateBranchOffice(payload)
        .then((res) => {
          let { data } = res.data;
          let { message, success } = data;
          if (success) {
            this.changeAction(1);
            this.alert("success", "Update_Success_0001");
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
          this.listAll();
        });
    },
    /**
     * Gets selected element data. // Obtiene los datos del elemento seleccionado.
     * Reloads allProvincesComponent. // Recarga allProvincesComponent
     * @method
     */
    getBranchUpdate(payload) {
      this.loading = this.$UtilFront.getLoadding();
      BranchOfficeRequest.getBranchUpdate({
        idBranchOffice: payload.idBranchoffice,
      })
        .then((res) => {
          let { success, data } = res.data.data;
          if (success) {
            this.updateBranch = true;
            this.modelFormCopy = { ...data };
            this.modelForm = data;
            this.modelFormCopy.provinceId = String(data.province.idProvince);
            this.modelForm.provinceId = String(data.province.idProvince);

            this.modelFormCopy.branchOfficeTypeId = String(
              data.branchOfficeTypeId
            );
            this.modelForm.branchOfficeTypeId = String(data.branchOfficeTypeId);
            this.changeAction(3);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    /**
     * Gets all branch officces. // Obtiene todas las sucursales.
     * @method
     */

    async confirmchangeStatusAdvanced(isReceptionStatus) {
      this.loading = this.$UtilFront.getLoadding();
      const payload = {
        idBranchOffice: this.model.idBranchoffice,
      };
      if (isReceptionStatus)
        payload.statusForReception = !!this.model.statusForReception;
      else payload.statusForReport = !!this.model.statusForReport;
      BranchOfficeRequest.updateStatusAdvanced(payload)
        .then(() => {
          const alertMessage = isReceptionStatus
            ? "Update_Status_0002"
            : "Update_Status_0003";
          this.alert("success", alertMessage);
        })
        .catch((error) => {
          const errorProp = isReceptionStatus
            ? "statusForReception"
            : "statusForReport";
          this.model[errorProp] = !this.model[errorProp];
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },

    /**
     * Gets all jurisdictions. // Obtiene todas las jurisdicciones.
     * @method
     */
    async listAllJurisdictions() {
      await JurisdictionRequest.listAll()
        .then((res) => {
          let { data } = res.data.data;
          if (data.objects) {
            this.jurisdictions = data.objects.map((obj) => {
              return {
                value: obj.idJurisdiction,
                text: obj.jurisdictionname,
              };
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    /**
     * Shows change status confirmation alert. // Muestra alerta de confirmacion de cambio de status.
     * @method
     */
    async confirmchangeStatus(item) {
      this.model = item;
      const { isConfirmed } = await this.$SwalConfirm.fire({
        icon: "warning",
        title: !item.available
          ? this.$t("branch_office.text_disable")
          : this.$t("branch_office.text_enable"),
        html: !item.available
          ? this.$t("branch_office.message_disable", {
              name: item.name,
            })
          : this.$t("branch_office.message_enable", {
              name: item.name,
            }),
      });
      if (!isConfirmed) {
        this.model.available = !this.model.available;
        return;
      }
      this.loading = this.$UtilFront.getLoadding();
      BranchOfficeRequest.updateStatus({
        idBranchOffice: this.model.idBranchoffice,
        status: this.model.available ? 1 : 0,
      })
        .then((res) => {
          const {
            data: {
              data: { message, success },
            },
          } = res;
          if (success) {
            this.alert("success", "Update_Status_0001");
          } else {
            this.model.available = !this.model.available;
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.model.available = !this.model.available;
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    showOptions(item) {
      this.model = item;
      this.dialog = true;
    },
    /**
     * Cancels and closes change status alert. // Cancela y cierra la alerta de cambio de status.
     * @method
     */
    cancelchangeStatus() {
      this.dialog = false;
      this.model.available = !this.model.available;
    },

    /**
     * Validates module permissions. / Valida los permisos del modulo.
     * @method
     * @param {Object} namePermission - Data permisions.
     * @returns {boolean} - true if permission exist.
     */
    withPermissionName(namePermission) {
      return this.$UtilFront.withPermissionName(namePermission);
    },
    /**
     * Generates and downloads excel file. / Genera y descarga archivo excel.
     * @method
     */
    excelTable() {
      this.$toast.success("Generando documento Xlsx");
      ExcelRequest.reportBranchOffice({});
    },
    listAllTypeBrachOffice() {
      BranchOfficeRequest.listAllTypeBrachOffice()
        .then((res) => {
          this.branchType = res.data;
        })
        .catch((error) => {
          console.log(error);
          this.alert("error", error.message);
        })
        .finally(() => {});
    },
    listAllSupplier() {
      BranchOfficeRequest.listAllSupplier()
        .then((res) => {
          this.supplier = res.data;
        })
        .catch((error) => {
          console.log(error);
          this.alert("error", error.message);
        })
        .finally(() => {});
    },
  },
  async created() {
    await this.listAll();
    try {
      this.loading = this.$UtilFront.getLoadding();
      await Promise.all([
        this.listAllProvinces(),
        this.listAllTypeBrachOffice(),
        this.listAllJurisdictions(),
        this.listAllSupplier(),
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      this.loading.hide();
    }
  },
  watch: {
    "$i18n.locale": function() {
      this.headers = BRANCH_OFFICE();
    },
  },
  components: {
    BranchOfficeTableComponent,
    AllProvincesComponent,
    FormBranchOfficeComponents,
  },
};
</script>
