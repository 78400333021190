<template>
  <div class="p-2">
    <v-data-table
      :headers="headers"
      :items="dataList"
      :search="search"
      fixed-header
      height="auto"
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      dense
    >
      <template v-slot:top>
        <div class="row justify-end">
          <div
            class="col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 pr-6 text-right"
          >
            <v-text-field
              dense
              v-model="search"
              :label="$t('table.search')"
            ></v-text-field>
          </div>
        </div>
      </template>

      <template v-slot:[`item.statusForReport`]="{ item }">
        <div v-if="item.statusForReport">
          <i class="mdi mdi-check-circle success-color"></i>
        </div>
        <div v-else>
          <i class="mdi mdi-close-circle error-color"></i>
        </div>
      </template>

      <template v-slot:[`item.statusForReception`]="{ item }">
        <div v-if="item.statusForReception">
          <i class="mdi mdi-check-circle success-color"></i>
        </div>
        <div v-else>
          <i class="mdi mdi-close-circle error-color"></i>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top v-if="isUpdate">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :disabled="item.branchOfficeTypeId == 5"
              :color="item.branchOfficeTypeId == 5 ? '#fff' : ''"
              small
              class="mr-4"
              @click="updateBranchOffice(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi mdi-square-edit-outline
            </v-icon>
          </template>

          <span>{{ $t("table.edit") }}</span>
        </v-tooltip>

        <v-tooltip v-if="isOptions" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="showOptions(item)"
              v-bind="attrs"
              v-on="on"
            >
              feather icon-settings
            </v-icon>
          </template>
          <span>Opciones avanzadas</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div>
          <v-switch
            color="success"
            v-if="isUpdateStatus"
            class="hand"
            hide-details
            dense
            @change="confirmchangeStatus(item)"
            inset
            v-model="item.available"
          >
          </v-switch>
          <div v-else>
            {{ item.available ? "Activa" : "Inactiva" }}
          </div>
        </div>
      </template>
    </v-data-table>

    <ButtonTableComponent v-if="isButton" v-on:excelTable="excelTable" />
  </div>
</template>

<script>
import { PermissionConstants } from "@/core";
import ButtonTableComponent from "@/common/table/ButtonTableComponent";

export default {
  props: {
    headers: {},
    isDetail: false,
    isUpdate: false,
    isButton: false,
    dataList: [],
    isOptions: false,
    isUpdateStatus: false,
  },
  data() {
    return {
      search: "",
      sending: false,
      permissions: PermissionConstants,
    };
  },
  components: {
    ButtonTableComponent,
  },
  methods: {
    /* General */
    excelTable() {
      this.$emit("excelTable");
    },
    detail(item) {
      this.$emit("detail", item);
    },
    showOptions(item) {
      this.$emit("showOptions", item);
    },
    updateBranchOffice(item) {
      this.$emit("updateBranchOffice", item);
    },
    confirmchangeStatus(item) {
      this.$emit("confirmchangeStatus", item);
    },
  },
};
</script>

<style scoped>
.success-color {
  color: #4caf50 !important;
  font-size: 22px;
}

.error-color {
  color: #ff3f3f !important;
  font-size: 22px;
}
</style>
