<template>
  <v-autocomplete
    preset-val="1"
    :error-messages="errors"
    :disabled="isDisabled"
    v-model="idProvince"
    :items="provinceList"
    @change="provinceChange"
    :label="provinceLabel"
  />
</template>

<script>
import { ProvinceRequest } from "@/core/request";

export default {
  props: {
    provinceLabel: "",
    errors: "",
  },
  data() {
    return {
      idProvince: 1,
      provinceList: [],
      isDisabled: false,
    };
  },
  mounted() {},
  methods: {
    provinceChange(data) {
      console.log(this.idProvince);
      this.$emit("provinceChange", this.idProvince);
    },
    reloadComponentDisabled(isDisabled) {
      this.isDisabled = isDisabled;
    },
    reloadComponent(id) {
      console.log(id);
      this.provinceList.map(element => {
        if(element.value === String(id)){
          this.idProvince = element.value;
        }
      })
    },
    listAllProvinces() {
    ProvinceRequest.listAll().then((response) => {
        let { data } = response.data.data;
        this.provinceList = data;
              console.log(this.provinceList);

        this.idProvince = "1";
      });
    },
  },
   created() {
    this.listAllProvinces();
  },
};
</script>
